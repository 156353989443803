









import {Component, Prop, Vue} from "vue-property-decorator";
import {IdAndName} from "../../models/interfaces/IdAndName";

@Component
export default class NameById extends Vue {
    @Prop() readonly items!: {[key: number]: IdAndName};
    @Prop() readonly values!: {id: number, name: string}[];
    @Prop({default: 0}) readonly value!: number;
    @Prop({default: ""}) readonly contentClass!: string;
    @Prop({default: ""}) readonly contentStyle!: string;

    private getDisplayName(value: number): string {
        if (this.items && Object.keys(this.items).length) {
            const items = this.items;
            if (items[value]) {
                return items[value].name;
            }
            return "---";
        }
        else if (this.values) {
            for (const val of this.values) {
                if (val.id === value) {
                    return val.name;
                }
            }

            return "---";
        }

        return "---";
    }
}
